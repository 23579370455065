import * as React from "react"
import * as styles from "./notFoundPage.module.scss"

import image from "./img/404.svg"

const NotFoundPage = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <div className={styles.contentContainer}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={image} alt="" />
      </div>
      <h1 className={styles.title}>404</h1>
      <h4 className={styles.byline}>Page Not Found</h4>
    </div>
  </div>
)

export default NotFoundPage
