import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import NotFoundPage from "../components/NotFoundPage"

const NotFound = (): JSX.Element => (
  <Layout>
    <SEO title="Page Not Found" />
    <BasicPage>
      <NotFoundPage />
    </BasicPage>
  </Layout>
)

export default NotFound
